.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 400px;
  background-color: white;
  padding: 30px 20px;
  min-height: 161px;
}

.button {
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.button:last-child {
  margin-bottom: 0;
}

.button:active {
  background-color: rgb(152, 150, 150);
  border: 2px solid rgb(152, 150, 150);
  color: white;
  transform: scale(0.95);
}

.icon {
  position: absolute;
  left: 20px;
  margin-right: 10px;
  transition: color 0.3s;
}

.button:active .icon {
  color: white;
}

.buttonText {
  margin-left: 30px;
}

.error {
  color: red;
  margin-bottom: 20px;
  text-align: center;
  max-width: 400px;
}
