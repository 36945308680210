/* Style for all input types including the select dropdown */
.addWordForm input[type="text"],
.addWordForm select {
  width: 100%; /* Makes the input and select box take full width of the form */
  padding: 10px;
  margin-bottom: 10px; /* Adds space below each form field */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Slightly rounded corners */
  box-sizing: border-box; /* Ensures padding doesn't affect the overall width */
}

.addWordForm select {
  background-color: white; /* Matches the input fields background color */
  color: black; /* Text color */
  cursor: pointer; /* Changes cursor to indicate it's selectable */
}

/* Hover styles for inputs and select */
.addWordForm input[type="text"]:hover,
.addWordForm select:hover {
  border-color: #17a2b8; /* Optional: Changes border color on hover */
}

/* Focus styles for inputs and select to match */
.addWordForm input[type="text"]:focus,
.addWordForm select:focus {
  border-color: #17a2b8; /* Highlight color when an element is focused */
  outline: none; /* Removes the default focus outline */
}

/* Style for the submit button */
.addWordForm button {
  width: 100%;
  padding: 10px;
  background-color: #17a2b8; /* Matching the focus color */
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 10px; /* Adds space above the button */
  cursor: pointer; /* Changes cursor to indicate it's clickable */
}

.addWordForm button:hover {
  background-color: #1391a8; /* A slightly darker shade for hover */
}

.addWordForm .formSection {
  margin-bottom: 20px;
}

.addWordForm h2 {
  margin-bottom: 10px;
  font-size: 1.25rem; /* Example size, adjust as needed */
}

.addWordForm select.inputError {
  border: 1px solid #ff5c5c;
}
